import React from "react"
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion'
import { ReactAudioPlayer } from "../../../reactPlayer"
import { getBlobURL } from "../../../../functions/utils"

const Module2 = ({ localProgram }) => {
    return (
        <Accordion preExpanded={[1]} allowMultipleExpanded='true'>
        <AccordionItem>
          <h2>​Module 2 – A Higher space</h2>
          <p>Please listen to audio files 1 - 8 plus the meditation on day 1.</p>
          <p>After day 1 for the rest of the week please just listen to the meditation. Please listen to the meditation 3 times or more before progressing to the next module.</p>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio 1 – The Mindfulness Effect <span>Duration: 05:43</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <h4>Overview</h4>
                  	<ul>
                        <li>Mindfulness means paying attention in a particular way</li>
                        <li>On purpose</li>
                        <li>In the present moment</li>
                        <li>And non judgmentally</li>
                     </ul>
              <ReactAudioPlayer
                  title="SHM : Module 2 : Audio 1 – The Mindfulness Effect"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m2A1,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-2-Track-1-What-Is-Mindfulness.mp3"}
              />
            </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio 2 – Am I Being Mindful? <span>Duration: 13:56</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <ul>
                <li>I could be experiencing some emotion and not be conscious of it until some time later. </li>
                <li>I break or spill things because of carelessness, not paying attention or thinking of something else.</li>
                <li>I find it difficult to stay focused on what’s happening in the present</li>
                <li>I tend to walk quickly without paying attention to what I experience along the way.</li>
                <li>I tend not to notice feelings of physical tension or discomfort until they really grab my attention.</li>
                <li>I rush through activities without being really attentive to them.</li>
                <li>I find myself preoccupied with the future or the past.</li>
                <li>I get so focused on the goal I want to achieve that I lose touch with  what I am doing right now to get there. </li>
                <li>I snack without being aware that I’m eating.</li>
                <li><em>Technology distracts me for being present</em></li>
                </ul>

                <ReactAudioPlayer
                  title="SHM : Module 2 : Audio 2 – Am I Being Mindful?"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m2A2,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-2-Track-2-Am-I-Mindful.mp3"}
                  showJumpControls="false"
                />
            </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio 3 – What Happens In The Body <span>Duration: 04:38</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <ul>
            <li>A study conducted by Wake Forest Baptist University found that meditation could reduce pain intensity by 40 percent and pain unpleasantness by 57 percent. Morphine and other pain-relieving drugs typically show a pain reduction of 25 percent.</li>
                        <li>Research published just last month in the journal Health Psychology shows that mindfulness is not only associated with feeling less stressed, it's also linked with decreased levels of the stress hormone cortisol.</li>
                        <li>The CDC says that 90% of all dis-ease is stress related</li>
       </ul>

      <ReactAudioPlayer
                  title="SHM : Module 2 : Audio 3 – What Happens In The Body"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m2A3,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-2-Track-3-What-happens-to-our-body.mp3"}
              />


      <p>Please take a few moments to rate how Hi your Chi is in each area out of 10 before listening to the next track:</p>

      <ul>
          <li>Health</li>
          <li>Relationships</li>
          <li>Success</li>
          <li>Contribution</li>
          <li>Spirituality</li>
          <li>Fulfillment</li>
      </ul>
            </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio 4 – HI CHI Mindfulness <span>Duration: 05:48</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <ul>
                        <li>Looking at experiences as if for the first time</li>
                        <li>Like a child. And what is a child? Fully present</li>
                        <li>The role of the mind in healing</li>
                        <li>Where is your awareness?</li>
                        <li>Invite it in to the HI CHI chill out zone</li>
                     </ul>

       <ReactAudioPlayer
                  title="SHM : Module 2 : Audio 4 – HI CHI Mindfulness"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m2A4,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-2-Track-4-Hi-Chi-Mindfulness.mp3"}
              />
    
            </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio 5 – Yin Energy <span>Duration: 03:35</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <ul>
                        <li>Yin and Yang</li>
                        <li>Being and doing</li>
                        <li>Softening into Yin</li>
                        <li>Loosening your grip</li>
                    </ul>

          <ReactAudioPlayer
                  title="SHM : Module 2 : Audio 5 – Yin Energy"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m2A5,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-2-Track-5-Softening-into-Yin.mp3"}
              />
            </AccordionItemPanel>
        </AccordionItem>


        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio 6 – Activating The Senses <span>Duration: 04:05</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <ul>
                        <li>When we slow down our thoughts we begin to feel</li>
                        <li>Feeling is the secret</li>
                        <li>When we create stillness in the mind our energy frees up so healing can occur</li>
                        <li>How often do you notice what is happening in your body?</li>
                        <li>Let’s begin by activating our senses to their full power </li>
                     </ul>
       
       <ReactAudioPlayer
                  title="SHM : Module 2 : Audio 6 – Activating The Senses"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m2A6,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-2-Track-6-Activating-the-senses.mp3"}
              />
            </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Meditation 1 – HI CHI Chillout Zen Den <span>Duration: 17:18</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <ul>
                        <li>Inviting your mind to the HI CHI chill out zone</li>
                        <li>The deckchair technique</li>
                        <li>Instant HI CHI</li>
                        <li>The body scan</li>
                        <li>Moving awareness outside out of your mind and body</li>
                        <li>Rate your HI CHI</li>
                        <li>Listen everyday for a week</li>
                     </ul>
              <ReactAudioPlayer
                  title="SHM : Module 2 : Meditation 1 – HI CHI Chillout Zen Den"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m2A7,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-2-Meditation-1-Hi-Chi-Chillout-Zen-Den.mp3"}
              />
            </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Meditation 2 – HI CHI Body Scan <span>Duration: 14:03</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
          
       <ReactAudioPlayer
                  title="SHM : Module 2 : Meditation 2 – HI CHI Body Scan"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m2A8,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-2-Meditation-Hi-Chi-Body-Scan-Intro.mp3"}
              />
    
            
            </AccordionItemPanel>
        </AccordionItem>  
    </Accordion>
    )
}

export default Module2