import React from "react"
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion'
import { ReactAudioPlayer } from "../../../reactPlayer"
import { getBlobURL } from "../../../../functions/utils"

const Module6 = ({ localProgram }) => {
    return (
        <Accordion preExpanded={[1]} allowMultipleExpanded='true'>
        <AccordionItem>
        <h2>​Module 6 – Your HI CHI daily power practice</h2>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio 1 - HI CHI Grounding <span>Duration: 05:33</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <p>Our HI CHI Starter has 3 main ingredients and always remains the same.</p>
                     <ul>
                        <li>This is really really key to having HI CHI!</li>
                        <li>Our job is to make the spiritual express itself within the physical world and take physical form.</li>
                        <li>If we are grounded then we can do this with ease.</li>
                        <li>If we are existing either too much in our upper bodies or even outside of it, then we find grounding a challenge.</li>
                        <li>We need grounding to stay balanced emotionally and mentally.</li>
                        <li>When we are grounded we literally power up our body physically.</li>
                        <li>It allows us to bring our spiritual essence into the physical world.</li>
                        <li>The Earth has an immense amount of energy and it powers us up, literally. </li>
                        <li>Mindfulness is very grounding because we are re engaging with the physical body</li>
                     </ul>
              <ReactAudioPlayer
                  title="SHM : Module 6 : Audio 1 - HI CHI Grounding"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m6A1,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-6-Track-1-Hi-Chi-Grounding.mp3"}
              />
            </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio 2 – What happens to our body when we Earth? <span>Duration: 04:05</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <ul>
                        <li>For all our physical systems to operate on full power we need a significant amount of physical energy</li>
                        <li>We need vitamin D and vitamin G</li>
                        <li>Your immune system functions optimally when your body has an adequate supply of electrons, which are easily and naturally obtained by barefoot contact with the Earth.</li>
                        <li>Every dis-ease has inflammation as a component. Therefore by grounding we will naturally reduce this.</li>
                        <li>We know from previous modules that what the mind imagines, the body translates as being real. We can enhance our physical energy this way too with HI CHI Grounding.</li>
                     </ul>
          <ReactAudioPlayer
                  title="SHM : Module 6 : Audio 2 – What happens to our body when we Earth?"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m6A2,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-6-Track-2-What-happens-to-our-body-when-we-Earth.mp3"}
              />
            </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio 3 – HI CHI Grounding with our Inner Child <span>Duration: 06:25</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <ul>
                        <li>You can keep reminding yourself to do so by inviting our inner child out to experience our day whilst grounding.</li>
                        <li>The easy way to do this is to combine mindfulness with gratitude and invite your Inner Child to the forefront of your awareness through each grounding activity.</li>
                     </ul>
      <ReactAudioPlayer
                  title="SHM : Module 6 : Audio 3 – HI CHI Grounding with our Inner Child"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m6A3,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-6-Track-3-Hi-Chi-Grounding-with-our-Inner-Child.mp3"}
              />
            </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio 4 – Your HI CHI Self Healing Mastery Menu <span>Duration: 12:47</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <p>Please use your HI CHI Self Healing Mastery Healing Menu to create your own bespoke self healing sessions.</p>
            <p><a href="https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-6-Hi-Chi-Self-Healing-Mastery-Module-6-Menu.pdf"><i class="fa fa-file-pdf-o" aria-hidden="true"></i> You can download the menu and also print it out.</a></p>
            <p>Play with all the different HI CHI breaths and techniques and find your unique style to heal.</p>
       <ReactAudioPlayer
                  title="SHM : Module 6 : Audio 4 – Your HI CHI Self Healing Mastery Menu"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m6A4,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-6-Track-4-Hi-Chi-Your-Hi-Chi-Self-Healing-Mastery-Menu.mp3"}
              />
    
            </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Meditation 1 <span>Duration: 21:40</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            

          <ReactAudioPlayer
                  title="SHM : Module 6 : Meditation 1"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m6A5,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-6-Meditation-1.mp3"}
              />
            </AccordionItemPanel>
        </AccordionItem>


        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Meditation 2 <span>Duration: 16:39</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
       <ReactAudioPlayer
                  title="SHM : Module 6 : Meditation 2"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m6A6,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-6-Meditation-2.mp3"}
              />
            </AccordionItemPanel>
        </AccordionItem>

    </Accordion>
    )
}

export default Module6