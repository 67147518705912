import React from "react"
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion'
import { ReactAudioPlayer } from "../../../reactPlayer"
import { getBlobURL } from "../../../../functions/utils"

const Module3 = ({ localProgram }) => {
    return (
        <Accordion preExpanded={[1]} allowMultipleExpanded='true'>
        <AccordionItem>
        <h2>​Module 3 – HI CHI Mindfulness - the extra vital ingredient</h2>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio 1 – Adding The Vital Ingredient <span>Duration: 02:49</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <h4>Overview</h4>
                  	<ul>
                        <li>How can add the Hi - est Chi to my mindfulness practice?</li>
                        <li>How can I become an alchemist?</li>
                        <li>How can I find a gap in my viewpoint big enough to create change?</li>
                     </ul>
              <ReactAudioPlayer
                  title="SHM : Module 3 : Audio 1 – Adding The Vital Ingredient"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m3A1,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-3-Track-1-Adding-The-Vital-Ingredient.mp3"}
              />
            </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio 2 – Gratitude - The Vital Ingredient <span>Duration: 05:09</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <ul>
                        <li>The secret mystical powers of gratitude</li>
                        <li>Whatever you feel grateful for will EXPAND</li>
                        <li>What happens if I am grateful for my mind?</li>
                        <li>Rather than try to overcome my mind - I will be grateful for it</li>
                        <li>Now experience the magic of a grateful mind</li>
                     </ul>

       
          <ReactAudioPlayer
                  title="SHM : Module 3 : Audio 2 – Gratitude - The Vital Ingredient"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m3A2,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-3-Track-2-Gratitude-The-Vital-Ingredient.mp3"}
              />
             
            </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio 3 – Why Change Your Viewpoint? <span>Duration: 01:36</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <blockquote>"When you change the way you look at things, the things you look at change."
                        <cite>Wayne Dyer</cite>
                     </blockquote>

            <ReactAudioPlayer          
                  title="SHM : Module 3 : Audio 3 – Why Change Your Viewpoint?"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m3A3,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-3-Track-3–Why-Change-Your-Viewpoint.mp3"}
              />
            </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio 4 – Gratitude Overcomes Resistance <span>Duration: 03:27</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <ul>
                        <li>What we resist in the body persists</li>
                        <li>The more we fight something the stronger it gets</li>
                        <li>It is essential that we access the hidden treasure within</li>
                        <li>What happens when we start saying THANK YOU</li>
                        <li>We enter into our highest state of healing</li>
                        <li>Gratitude strengthens the immune system</li>
                     </ul>

       <ReactAudioPlayer
                  title="SHM : Module 3 : Audio 4 – Gratitude Overcomes Resistance"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m3A4,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-3-Track-4-Gratitude-Overcomes-Resistance.mp3"}
              />
    
            </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio 5 – What You Notice... <span>Duration: 05:50</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <p>...will reveal itself to you</p>

          <p>Before listening to audio 5 please watch this video</p>
          <div class="embed-container">
              <iframe src="https://player.vimeo.com/video/84802749" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
          </div>

          <ReactAudioPlayer
                  title="SHM : Module 3 : Audio 5 – What You Notice..."
                  audioURL={getBlobURL(
                    localProgram?.audio?.m3A5,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-3-Track-5-What-You-Notice.mp3"}
              />
            </AccordionItemPanel>
        </AccordionItem>


        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio 6 – Miracles Happen Here Now <span>Duration: 08:01</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <ul>
                        <li>At a basic level in NLP we know that if you buy a red car suddenly you see lots of red cars</li>
                        <li>As we switch our awareness we see an entirely new reality</li>
                        <li>As you move into gratitude for the situation we access the miraculous power of gratitude </li>
                        <li>It’s for the best</li>
                        <li>Asking for the higher insights</li>
                        <li>Advanced gratitude is miraculous alchemy</li>
                     </ul>
       
       <ReactAudioPlayer
                  title="SHM : Module 3 : Audio 6 – Miracles Happen Here Now "
                  audioURL={getBlobURL(
                    localProgram?.audio?.m3A6,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-3-Track-6-Miracles-Happen-Here-Now.mp3"}
              />
            </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio 7 – HI CHI Amplified <span>Duration: 04:34</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <ul>
                        <li>How can we turbo rocket boost our HI CHI mindfulness meditation practice?</li>
                        <li>How can we find a gap in our situation to elevate it for healing?</li>
                     </ul>
              <ReactAudioPlayer
                  title="SHM : Module 3 : Audio 7 – HI CHI Amplified"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m3A7,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-3-Track-7-Hi-Chi-Amplified.mp3"}
              />
            </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Meditation 1 - HI CHI With Mindful Gratitude <span>Duration: 38:26</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <p>Please listen to each of these meditations. You can choose which one to listen to on each day and remember to rate your Chi at the beginning of each week.</p>
       
       <ReactAudioPlayer
                  title="SHM : Module 3 : Meditation 1 - HI CHI With Mindful Gratitude"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m3A8,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-3-Meditation-1.mp3"}
              />
    
            
            </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Meditation 2 - Opening Your Gift <span>Duration: 17:30</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
          <ReactAudioPlayer
                  title="SHM : Module 3 : Meditation 2 - Opening Your Gift"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m3A9,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-3-Meditation-2.mp3"}
              />
            </AccordionItemPanel>
        </AccordionItem>

        
    </Accordion>
    )
}

export default Module3