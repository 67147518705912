import React from "react"
import { useShoppingCart } from 'use-shopping-cart'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import {
  Section,
} from "../styledComponents/section"
import Module1 from "./Program/SelfHealingMastery/Modules/module1";
import Module2 from "./Program/SelfHealingMastery/Modules/module2";
import Module3 from "./Program/SelfHealingMastery/Modules/module3";
import Module4 from "./Program/SelfHealingMastery/Modules/module4";
import Module5 from "./Program/SelfHealingMastery/Modules/module5";
import Module6 from "./Program/SelfHealingMastery/Modules/module6";

const ShmProgram = ({ localProgram }) => {
    return (
        <>
        <Section id="learn" className="shm-program">
          <Tabs>
            <TabList>
              <Tab>Module 1</Tab>
              <Tab>Module 2</Tab>
              <Tab>Module 3</Tab>
              <Tab>Module 4</Tab>
              <Tab>Module 5</Tab>
              <Tab>Module 6</Tab>
            </TabList>
            <TabPanel>
                <Module1 localProgram={localProgram} />
            </TabPanel>
            <TabPanel>
                <Module2 localProgram={localProgram} />
            </TabPanel>
            <TabPanel>
                <Module3 localProgram={localProgram} />
            </TabPanel>
            <TabPanel>
                <Module4 localProgram={localProgram} />
            </TabPanel>
            <TabPanel>
                <Module5 localProgram={localProgram} />
            </TabPanel>
            <TabPanel>
                <Module6 localProgram={localProgram} />
            </TabPanel>
          </Tabs>
        </Section>
        </>
    )
}

export default ShmProgram