import React from "react"
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion'
import { ReactAudioPlayer } from "../../../reactPlayer"
import { getBlobURL } from "../../../../functions/utils"

const Module1 = ({ localProgram }) => {
    return (
        <Accordion preExpanded={[1]} allowMultipleExpanded='true'>
        <AccordionItem>
          <h2>​Module 1 – A Higher space</h2>
          <p>Please listen to audio files 1 - 8 plus the meditation on day 1.</p>
          <p>After day 1 for the rest of the week please just listen to the meditation. Please listen to the meditation 3 times or more before progressing to the next module.</p>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio 1 – HI CHI Self Healing Mastery Mastering The Basics <span>Duration: 23:26</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
              <h4>Overview</h4>
              <ul>
                  <li>All Masters spend a lot of their time mastering and remastering the basics</li>
                  <li>All healing secrets are hidden very obviously in the basics</li>
                  <li>Our innate blueprint is intelligent</li>
                  <li>Giving yourself a gentle reminder</li>
              </ul>
              <ReactAudioPlayer
                  title="SHM : Module 1 : Audio 1 – HI CHI Self Healing Mastery Mastering The Basics"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m1A1,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-1-Track-1-Introduction.mp3"}
              />
            </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio 2 – Becoming A HI CHI Magic Mirror <span>Duration: 07:07</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <ul>
              <li>The Mirror Effect is just the beginning of having HI CHI</li>
              <li>It is BEING not doing that creates the greatest changes</li>
              <li>Pushing on the outside world creates resistance</li>
              <li>Our internal reality affects our reality in its entirety</li>
              <li>The change must happen within ourselves first</li>
              <li>View yourself as a Magic Mirror </li>
              <li>We experience everything in our view as real</li>
              <li>Science proves this with Olympic athletes</li>
              <li>Learning to mirror the natural rhythms of the universe, life and health and order</li>
              <li>Our body then returns to it’s natural rhythm</li>
          </ul>

          <p>Before listening to audio 2 please watch these two videos:</p>

          <div class="embed-container">
            {/* TODO Switch to react video player if local? Or don't render embed section */}
          <iframe width="560" height="315" src="https://www.youtube.com/embed/vLT3A0a3hoQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <div class="embed-container">
          <iframe width="560" height="315" src="https://www.youtube.com/embed/nZamwCZOWHI" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </div>
          <ReactAudioPlayer
                  title="SHM : Module 1 : Audio 2 – Becoming A HI CHI Magic Mirror"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m1A2,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-1-Track-2-Hi-Chi-Magic-Mirror.mp3"}
              />
              <h3>HI CHI lazer pointers</h3>
              <p>Is there someone in your life who makes you a HI CHI Magic Mirror of love and happiness? </p>
              <p>Which teachers are you mirroring?</p>
              <p>Who do you feel amazing around?</p>
            </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio 3 – Scale of Life Force Energy <span>Duration: 03:38</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <ul>
          <li>Scale of Life Force Energy - a precise scale of where are you right now and where you want to be</li>
          <li>How much LFE is flowing through you?</li>
          <li>The more LIFE flows through you the healthier you are on ALL levels</li>
          <li>High vibration  = good health</li>
          <li>Key areas of life; health, relationships, success, contribution, spirituality and fulfillment</li>
          <li>How much LFE is flowing through each area right now?</li>
          <li>Rate your Chi now</li>
       </ul>

      <ReactAudioPlayer
                  title="SHM : Module 1 : Audio 3 – Scale of Life Force Energy"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m1A3,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-1-Track-3-Scale-of-Life-Force-Energy.mp3"}
              />


      <p>Please take a few moments to rate how Hi your Chi is in each area out of 10 before listening to the next track:</p>

      <ul>
          <li>Health</li>
          <li>Relationships</li>
          <li>Success</li>
          <li>Contribution</li>
          <li>Spirituality</li>
          <li>Fulfillment</li>
      </ul>
            </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio 4 – Shifting into a Higher space <span>Duration: 08:17</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <ul>
          <li>Where is your focus right now?</li>
          <li>In HI CHI we focus on health</li>
          <li>Taking your focus away from the issue</li>
          <li>Learning to pay attention to how we feel</li>
          <li>How you feel influences your life experience and immunity</li>
          <li>Where is your HI CHI lazer pointing?</li>
          <li>Consciously re routing your focus</li>
       </ul>

       <ReactAudioPlayer
                  title="SHM : Module 1 : Audio 4 – Shifting into a Higher space"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m1A4,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-1-Track-4-Your-Hi-Chi-Lazer.mp3"}
              />
    
            </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio 5 – The Essence of HI CHI... learn from kids! <span>Duration: 05:33</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <ul>
              <li>Do you remember how to be present and what you love? KIDS DO</li>
              <li>ALL ENERGY WORK AND HEALING IS CHILD'S PLAY</li>
              <li>Children are closest to source energy</li>
              <li>Access your most aware self through play</li>
              <li>Have the faith to dive in and play</li>
              <li>Reconnect to what you loved doing as a Child</li>
              <li>Reconnect with your imagination</li>
              <li>What LIGHTS you up?</li>
              <li>We purposefully create an enjoyable game</li>
          </ul>

          <p>Before listening to audio 5 please watch this video</p>
          <div class="embed-container">
              <iframe src="https://player.vimeo.com/video/84802749" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen title="Kayden's first time experiencing rain"></iframe>
          </div>

          <ReactAudioPlayer
                  title="SHM : Module 1 : Audio 5 – The Essence of HI CHI... learn from kids!"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m1A5,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-1-Track-5-Essence-of-Hi-Chi-learn-from-kids.mp3"}
              />
            </AccordionItemPanel>
        </AccordionItem>


        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                ​​Audio 6 – Your HI CHI Mirror of Clarity <span>Duration: 11:23</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <ul>
          <li>Are you giving out mixed messages?</li>
          <li>Being a HI CHI Mirror of Clarity is essential for all Self Healing</li>
          <li>Setting crystal clear intentions</li>
          <li>This is one of the most important aspects of engaging with the your higher self</li>
          <li>Now you are clear - it is time to align</li>
       </ul>
       
       <ReactAudioPlayer
                  title="SHM : Module 1 : ​​Audio 6 – Your HI CHI Mirror of Clarity"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m1A6,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-1-Track-6-Your-Hi-Chi-Mirror-of-Clarity.mp3"}
              />
            </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                ​​Audio 7 – Your HI CHI Healing Potential <span>Duration: 04:26</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <ul>
                  <li>How BIG are the possibilities?</li>
                  <li>The Roger Bannister Effect</li>
                  <li>The HI CHI Healing Effect</li>
                  <li>What to expect?</li>
              </ul>
              <ReactAudioPlayer
                  title="SHM : Module 1 : ​​Audio 7 – Your HI CHI Healing Potential"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m1A7,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-1-Track-7-Your-Healing-Potential.mp3"}
              />
            </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio 8 – A life you love <span>Duration: 01:15</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <ul>
          <li>Bringing our your inner Child to play</li>
          <li>Remember what you love</li>
          <li>Focus on health</li>
          <li>Training your awareness of your HI CHI Mirror</li>
          <li>Crystal clear intentions - your HI CHI Mirror of Clarity</li>
          <li>Open up to a full healing</li>
       </ul>
       
       <ReactAudioPlayer
                  title="SHM : Module 1 : Audio 8 – A life you love"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m1A8,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-1-Track-8-Review-of-a-Hi-Ch-Life-you-Love.mp3"}
              />
    
            
            </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Meditation – Experiential Processes for SHM Module 1 <span>Duration: 27:21</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <ul>
              <li>Listen to the meditation everyday for 1 week</li>
              <li>Opening to self healing</li>
              <li>Receiving the highest level of impact from this course</li>
              <li>Connecting to everyone else on the course</li>
              <li>Intending healing for all</li>
              <li>Bringing your inner child out to play</li>
              <li>Knowing what you want</li>
              <li>Writing crystal clear intentions</li>
              <li>Rate your Chi at the end of the week!!</li>
          </ul>
          
          <ReactAudioPlayer
                  title="SHM : Module 1 : Meditation – Experiential Processes for SHM Module 1"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m1A9,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-1-Meditation.mp3"}
              />
            </AccordionItemPanel>
        </AccordionItem>

        
    </Accordion>
    )
}

export default Module1