import React from "react"
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion'
import { ReactAudioPlayer } from "../../../reactPlayer"
import { getBlobURL } from "../../../../functions/utils"

const Module4 = ({ localProgram }) => {
    return (
        <Accordion preExpanded={[1]} allowMultipleExpanded='true'>
        <AccordionItem>
        <h2>​​Module 4 – Your HI CHI Healing Breaths</h2>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio 1 – Breathing With HI CHI <span>Duration: 06:32</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <h4>Overview</h4>
                     <ul>
                        <li>The flow of life can be found in our breath</li>
                        <li>Our life force energy is expressed in the breath</li>
                        <li>How do you breathe when you are stressed?</li>
                        <li>Mindful breathing</li>
                     </ul>
              <ReactAudioPlayer
                  title="SHM : Module 4 : Audio 1 – Breathing With HI CHI"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m4A1,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-4-Track-1-Breathing-With-Hi-Chi.mp3"}
              />
            </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio 2 – Opening Your Breath <span>Duration: 03:44</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <ul>
                        <li>As you open your mind and slow down your thoughts your breathing relaxes</li>
                        <li>Healing releases occur in the moment of a new breath</li>
                     </ul>
          <ReactAudioPlayer
                  title="SHM : Module 4 : Audio 2 – Opening Your Breath"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m4A2,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-4-Track-2-Opening-Your-Breath.mp3"}
              />
            </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio 3 – Oxygenating Your Cells <span>Duration: 04:19</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <ul>
                        <li>Our cells need to breathe... fully</li>
                        <li>Energy has to transform</li>
                        <li>Giving them HI CHI allows the cells to transform</li>
                     </ul>
      <ReactAudioPlayer
                  title="SHM : Module 4 : Audio 3 – Oxygenating Your Cells"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m4A3,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-4-Track-3-Oxygenating-Your-Cells.mp3"}
              />


            </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio  4 – We Are Being Breathed <span>Duration: 04:16</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <ul>
                        <li>When we let go we realise that we are being breathed</li>
                        <li>Allowing yourself to be breathed</li>
                        <li>Noticing where the breath is coming from</li>
                        <li>Where is it going?</li>
                        <li>The HI CHI breaths are designed to connect you to an awareness of Oneness</li>
                     </ul>

       <ReactAudioPlayer
                  title="SHM : Module 4 : Audio  4 – We Are Being Breathed"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m4A4,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-4-Track-4-We-Are-Being-Breathed.mp3"}
              />
    
            </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio 5 – The Universal Lung <span>Duration: 01:58</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <ul>
                        <li>The highest change is knowing we are One with the Universe</li>
                        <li>Putting our awareness on breathing from our lungs limits our capacity </li>
                        <li>What happens if the entire universe becomes our lungs?</li>
                        <li>Our source of air/life force becomes infinite</li>
                        <li>We unite our body with the awareness it needs to heal</li>
                    </ul>

          <ReactAudioPlayer
                  title="SHM : Module 4 : Audio 5 – The Universal Lung"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m4A5,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-4-Track-5-The-Universal-Lung.mp3"}
              />
            </AccordionItemPanel>
        </AccordionItem>


        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio 6 – Universal Lung Breath <span>Duration: 02:45</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <ul>
                        <li>Make your lung an expanded balloon the size of the universe as you breath</li>
                        <li>Bring in the emotion of freedom and expansion</li>
                        <li>Soften into Yin</li>
                        <li>Moving your awareness</li>
                        <li>Training ourselves to feel</li>
                        <li>As if your lung is universal</li>
                        <li>Soften your mind and expand your awareness so it has no beginning and no end.</li>
                     </ul>
       
       <ReactAudioPlayer
                  title="SHM : Module 4 : Audio 6 – Universal Lung Breath"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m4A6,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-4-Track-6-Universal-Lung-Breath.mp3"}
              />
            </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio 7 – Breathe Serene a Breath Of Oneness <span>Duration: 04:39</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <ul>
                        <li>Imagine a still lake, with a warm sun and a slight breeze creating gentle waves</li>
                        <li>Make it bigger so it exists all around you, you are in the middle of this scene</li>
                        <li>Now bring it within you so that you merge with it</li>
                        <li>Breathing in the calm through your body and mind and breathing out calm</li>
                        <li>Breathing in I become One with the stillness, breathing out I become one with the stillness</li>
                        <li>Your mind is as still as the lake</li>
                     </ul>
              <ReactAudioPlayer
                  title="SHM : Module 4 : Audio 7 – Breathe Serene a Breath Of Oneness"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m4A7,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-4-Track-7-Breathe-Serene-a-Breath-Of-Oneness.mp3"}
              />
            </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio 8 – The Grateful Breath <span>Duration: 03:06</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <ul>
                        <li>With each inhale feel grateful for the breath</li>
                        <li>With each exhale feel grateful for the breath</li>
                        <li>This is a shortcut to understanding that we are being breathed</li>
                        <li>The grateful breath connects you to Source energy</li>
                     </ul>
       
       <ReactAudioPlayer
                  title="SHM : Module 4 : Audio 8 – The Grateful Breath"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m4A8,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-4-Track-8-The-Grateful-Breath.mp3"}
              />
    
            
            </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Meditation - Your Healing Breaths <span>Duration: 21:34</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <ul>
              <li>Listen to the meditation everyday for 1 week</li>
              <li>Opening to self healing</li>
              <li>Receiving the highest level of impact from this course</li>
              <li>Connecting to everyone else on the course</li>
              <li>Intending healing for all</li>
              <li>Bringing your inner child out to play</li>
              <li>Knowing what you want</li>
              <li>Writing crystal clear intentions</li>
              <li>Rate your Chi at the end of the week!!</li>
          </ul>
          
          <ReactAudioPlayer
                  title="SHM : Module 4 : Meditation - Your Healing Breaths"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m4A9,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-4-Meditation.mp3"}
              />
            </AccordionItemPanel>
        </AccordionItem>

        
    </Accordion>
    )
}

export default Module4