import React from "react"
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion'
import { ReactAudioPlayer } from "../../../reactPlayer"
import { getBlobURL } from "../../../../functions/utils"

const Module5 = ({ localProgram }) => {
    return (
        <Accordion preExpanded={[1]} allowMultipleExpanded='true'>
        <AccordionItem>
            <h2>Module 5 – Your magic HI CHI Mirror Effect</h2>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio 1 – The HI CHI Magic Mirror Of Love <span>Duration: 06:18</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <h4>Overview</h4>
                     <ul>
                        <li>As we enhance our awareness we begin to see magic in our mirror</li>
                        <li>The way we view our world is reflected back to us</li>
                        <li>The highest energy that exists is Love</li>
                        <li>Love melts anything and transforms it into a higher energy</li>
                        <li>This is called The Love Effect</li>
                     </ul>
              <ReactAudioPlayer
                  title="SHM : Module 5 : Audio 1 – The HI CHI Magic Mirror Of Love"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m5A1,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-5-Track-1-The-Hi-Chi-Magic-Mirror-Of-Love.mp3"}
              />
            </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio 2 – The Miracle Of Love - Measured <span>Duration: 05:10</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <ul>
                        <li>When we feel Love we release a powerful cocktail of chemicals into our brains and body</li>
                        <li>Dopamine, serotonin and oxytocin release feelings of euphoria, pleasure and serenity</li>
                        <li>A Mother’s love in early life has been shown to develop the size of the hippocampus in the brain and the volume of the amygdalae</li>
                        <li>The most powerful state of healing we can be in is that of Self Love</li>
                     </ul>
          <ReactAudioPlayer
                  title="SHM : Module 5 : Audio 2 – The Miracle Of Love - Measured"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m5A2,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-5-Track-2-The-Miracle-Of-Love-Measured.mp3"}
              />
            </AccordionItemPanel>
        </AccordionItem>
        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio 3 – The Heart Centre <span>Duration: 04:49</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <ul>
                        <li>What is the heart centre?</li>
                        <li>The heart chakra</li>
                        <li>The heart brain</li>
                        <li>Unconditional love is a CREATIVE and powerful energy that heals</li>
                        <li>When our heart centre is open we find love flows naturally within us and to others</li>
                        <li>Our ability to feel and flow with Love has a direct effect on healing our mind, body and spirit.</li>
                     </ul>
      <ReactAudioPlayer
                  title="SHM : Module 5 : Audio 3 – The Heart Centre"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m5A3,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-5-Track-3-The-Heart-Centre.mp3"}
              />
            </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio 4 – The Love Effect <span>Duration: 04:01</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <ul>
                <li>Organisations such as The HeartMath Institute are releasing groundbreaking research about the impact of a balanced heart centre.</li>
            </ul>
            <p><em>“The heart is, in fact, a highly complex information-processing center with its own functional brain, commonly called the heart brain, that communicates with and influences the cranial brain via the nervous system, hormonal system and other pathways. These influences affect brain function and most of the body’s major organs and play an important role in mental and emotional experience and the quality of our lives.”</em></p>

            <h4>Health Improvements</h4>
            <ul>
                <li>Hypertension</li>
                <li>Arrhythmias</li>
                <li>Autoimmune disorders</li>
                <li>Environmental sensitivity</li>
                <li>Sleep disorders</li>
                <li>Drug and alcohol addiction</li>
                <li>Anger</li>
                <li>Heart failure</li>
            </ul>
            <ul>
                <li>Chronic pain</li>
                <li>Fibromyalgia</li>
                <li>Chronic fatigue</li>
                <li>Anxiety disorders</li>
                <li>Depression</li>
                <li>PTSD</li>
                <li>ADD/ADHD</li>
                <li>Eating disorders</li>
            </ul>
            
       <ReactAudioPlayer
                  title="SHM : Module 5 : Audio 4 – The Love Effect"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m5A4,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-5-Track-4-The-Love-Effect.mp3"}
              />
    
            </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio 5 – Loving What Is... <span>Duration: 04:08</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <ul>
                        <li>Loving what is dissolves and resolves resistance</li>
                        <li>Changes happen naturally in this state</li>
                        <li>Our system resets itself</li>
                        <li>We view our current state with love</li>
                     </ul>

          <ReactAudioPlayer
                  title="SHM : Module 5 : Audio 5 – Loving What Is..."
                  audioURL={getBlobURL(
                    localProgram?.audio?.m5A5,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-5-Track-5-Health-Improvements.mp3"}
              />
            </AccordionItemPanel>
        </AccordionItem>


        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio 6 – Prayer To Open The Heart <span>Duration: 02:50</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <ul>
                        <li>We always begin with a higher prayer to experience our hearts as being open</li>
                        <li>Connecting to everyone’s heart in our HI CHI group and the universal heart</li>
                        <li>As we open up our awareness of Love existing Universally we realise we are a raindrop within an ocean</li>
                        <li>The Source of Love is infinite</li>
                     </ul>
       
       <ReactAudioPlayer
                  title="SHM : Module 5 : Audio 6 – Prayer To Open The Heart"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m5A6,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-5-Track-6-Loving-What-Is.mp3"}
              />
            </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio 7 - Breath Of Love <span>Duration: 03:51</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <ul>
                        <li>Recall a time when you felt unconditional Love</li>
                        <li>Place your hands over your heart</li>
                        <li>Colour your breath with the intention of Love</li>
                        <li>Feel an awareness of the aura of your body </li>
                        <li>Open your awareness to being BREATHED with Love</li>
                        <li>This is how you effortlessly flow Love throughout your mind, body and spirit</li>
                        <li>Feel this awareness expand literally through your mind, your entire body and your spirit</li>
                        <li>As thoughts arise - Love them too</li>
                     </ul>

              <ReactAudioPlayer
                  title="SHM : Module 5 : Audio 7 - Breath Of Love"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m5A7,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-5-Track-7-Prayer-To-Open-The-Heart.mp3"}
              />
            </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio 8 - Design Your Love Breath <span>Duration: 03:35</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <ul>
                     <li>Take the time to design your own personal breath of Love</li>
                     <li>Fill it with detail</li>
                     <li>Use your senses</li>
                     <li>As you open your Breath of Love meditations call on your own unique access point</li>
                     <li>Whenever your mind drifts place your awareness back onto your Love breath</li>
                     <li>Each time you do so it will become a Magic Mirror of Love for you</li>
                     <li>The more time you spend in a state of Love the more HI CHI Healing Energy flows through you</li>
                  </ul>
       <ReactAudioPlayer
                  title="SHM : Module 5 : Audio 8 - Design Your Love Breath"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m5A8,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-5-Track-8-Breath-Of-Love.mp3"}
              />
    
            
            </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Audio 9 - Overview <span>Duration: 02:43</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
           
          
          <ReactAudioPlayer
                  title="SHM : Module 5 : Audio 9 - Overview"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m5A9,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-5-Track-9-Design-Your-Love-Breath.mp3"}
              />
            </AccordionItemPanel>
        </AccordionItem>

        <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton className="accordion__button audio-module-item">
                Meditations <span>Duration: 19:21</span>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanel>
            <h4>Meditation 1 - Your HI CHI Magic Mirror of Love</h4>
            <ReactAudioPlayer
                  title="SHM : Module 5 : Meditation 1 - Your HI CHI Magic Mirror of Love"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m5A10,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-5-Meditation-1.mp3"}
              />
            <h4>Meditation 2 - Your HI CHI Magic Mirror of Love</h4>
               <ReactAudioPlayer
                  title="SHM : Module 5 : Meditation 2 - Your HI CHI Magic Mirror of Love"
                  audioURL={getBlobURL(
                    localProgram?.audio?.m5A11,
                    "audio"
                  ) || "https://s3-eu-west-1.amazonaws.com/cdn-europe.hichi-life.com/audio/shm/Module-5-Meditation-2.mp3"}
              />
          
            </AccordionItemPanel>
        </AccordionItem>


    </Accordion>
    )
}

export default Module5